.navbar {
    display: flex;
    align-items: center;
    justify-content: space-between; /* This spreads out the logo and the links */
    background-color: #F8FAFC; /* Dark blue color */
    color: #363636; /* Changing text color to white for better readability */
    height: 80px;
    padding: 24px 40px;
}
  
.navbar-links {
    display: flex;
    align-items: center;
    max-height: 40px;
}
  
.navbar-item {
    margin: 0px 0px; /* Adds some space between the links */
    color: black; /* Ensure link text is white */
    text-decoration: none; /* Optional: Removes underline from links */
}

.navbar-item:last-child {
  margin-right: 0px; /* Remove margin for last item */
}

.icon.is-navbar {
  color: rgba(0, 0, 0, .70);
}

.icon.is-navbar:hover {
  color: #1861DD;
}

/* Styles for the dropdown menu */
.navbar-dropdown {
  background-color: #fff; /* Match the navbar background color */
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.15); /* Optional: Adds a shadow for depth */
  border-top: 0px;
  border-radius: 6px;
  top: 45px; /* Adjust the vertical position */
  right: 8px; /* Adjust the horizontal position */
  position: absolute; /* Ensure the dropdown is positioned absolutely */
  /* Additional styles to ensure it doesn't conflict with Bulma's defaults */
  left: auto;
  transform: none;
  min-width: 180px;
}

.navbar-dropdown, a.navbar-item  {
  padding: 8px 0px !important;
}

.navbar-dropdown .navbar-item {
  /* color: white; /* Ensure dropdown text is white */
  color: #363636; /* Text color */
}

.navbar-dropdown .navbar-item:hover {
  /* background-color: #163252; /* Slightly lighter blue for hover effect */
  background-color: rgba(0, 0, 0, .60); /* Slightly lighter blue for hover effect */
}

.navbar-link {
  color: #363636; /* Ensure dropdown trigger link is white */
}

.navbar-link:hover {
  background-color: #163252; /* Slightly lighter blue for hover effect */
}

/* Ensure the divider matches the overall navbar theme */
.navbar-divider {
  background-color: rgba(0, 0, 0, .2); /* Darker blue for the divider */
  height: 1px;
}

.logo {
  max-height: 32px;
}

.navbar-item {
  padding: 16px 8px;
  font-family: var(--font-primary);
  font-size: 14px !important;
  font-style: normal;
  font-weight: 500;
  line-height: 20px !important; /* 142.857% */
}

.navbar-label {
  /* background-color: rgba(0, 0, 0, .05); */
  padding: 0px 16px;
}