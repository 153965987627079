/* Main container to center the layout */
.research-container {
  display: flex;
  flex-direction: column; /* Arrange items vertically */
  align-items: center; /* Center items horizontally */
  width: 100%;
  margin-top: 50px;
  padding: 20px;
  background-color: #f5f5f5;
}

/* Form styling */
.input-form {
  display: flex;
  width: 100%;
  max-width: 800px;
  margin-bottom: 20px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

/* Chat input box styling */
.chat-input {
  flex: 1;
  padding: 14px 20px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 5px 0 0 5px;
  outline: none;
}

/* Input focus styling */
.chat-input:focus {
  border-color: #4caf50;
  box-shadow: 0px 0px 4px rgba(76, 175, 80, 0.5);
}

/* Submit button styling */
.submit-button {
  background-color: #4caf50;
  color: white;
  padding: 0 20px;
  font-size: 16px;
  border: none;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

/* Hover effect for button */
.submit-button:hover {
  background-color: #45a049;
}

/* Results container below the input box */
.results-container {
  width: 100%;
  max-width: 800px;
  padding: 10px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

/* Individual result item styling */
.result-item {
  margin-bottom: 20px;
  padding: 10px;
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.result-item p {
  margin: 5px 0;
}
